











































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import JwlButton from '@/components/JwlButton.vue';

@Component({
  components: {
    JwlButton,
  },
})
export default class JwlSubmitRow extends Vue {
  @Prop(Number)
  formSubmitStatus!: number;

  @Prop(String)
  transKey!: string;

  @Prop(Boolean)
  showSmall!: boolean;

  @Prop(Boolean)
  withSpace!: boolean;

  get rootClasses (): Record<string, boolean> {
    return {
      'jwl-submit-row--small': this.showSmall,
      'jwl-submit-row--with-space': this.withSpace,
    };
  }
}
