










































































import draggable from 'vuedraggable';
import Vue from 'vue';
import Component from 'vue-class-component';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import BackBar from '@/components/BackBar.vue';
import JwlButton from '@/components/JwlButton.vue';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';
import jwlClassroomStudent from './components/SisClassroomStudent.vue';
import SisClassroomFacilitators from './components/SisClassroomFacilitators.vue';
import { Programme } from '@/store/interfaces/Programme';

const IconTrashAlt = () => import('common-modules/src/assets/fa-icons/solid/trash-alt.svg');
const CommonError = () => import('common-modules/src/components/CommonError.vue');

const STATUS_NOTHING = 0;
const STATUS_SUBMITTING = 1;
const STATUS_SUCCESS = 2;
const STATUS_ERROR = -1;

@Component({
  components: {
    BackBar,
    CommonError,
    CommonIcon,
    draggable,
    IconTrashAlt,
    JwlButton,
    SisClassroomFacilitators,
    jwlClassroomStudent,
    JwlSubmitRow,
  },
})
export default class SisProgrammeClasses extends Vue {
  programme: Programme|null = null;
  classes: any|null = null;
  courses: any|null = null;
  facilitators = null;
  state: 'online'|'onsite'|string = 'online';
  loaded = false;
  error = null;
  submitStatus = STATUS_NOTHING;
  newClasses: Record<string, any[]> = {
    online: [],
    onsite: [],
  };

  fetchData (): void {
    this.loaded = false;
    this.$store.dispatch('getData', `programme/classes/${this.$route.params.programme}`)
      .then((data) => {
        this.programme = data.programme;
        this.classes = data.classes;
        this.courses = data.courses;
        this.facilitators = data.facilitators;
        if (data.classes.unsorted.length > 0) {
          this.distributeUnsorted();
        }
        this.loaded = true;
      })
      .catch((e) => {
        this.error = e;
      });
  }

  updateData (): void {
    if (this.submitStatus !== STATUS_SUBMITTING) {
      this.submitStatus = STATUS_SUBMITTING;
      const FD = new FormData();
      FD.append('classes', JSON.stringify(this.classes));

      this.$store.dispatch('postData', {
        url: `programme/classes/${this.$route.params.programme}`,
        formData: FD,
      }).then((data) => {
        this.courses.unsorted = [];
        this.newClasses = {
          online: [],
          onsite: [],
        };
        this.$store.commit('UPDATE_PROGRAMME', data);
        this.submitStatus = STATUS_SUCCESS;
        setTimeout(() => {
          this.submitStatus = STATUS_NOTHING;
        }, 2500);
      }).catch((e) => {
        this.error = e;
        this.submitStatus = STATUS_ERROR;
      });
    }
  }

  setState (state: string): void {
    this.state = state;
  }

  stateActive (state: string): boolean {
    return state === this.state;
  }

  updateCourse (courseIndex: number, newValues: any): void {
    this.classes[this.state][courseIndex].courses = newValues;
  }

  distributeUnsorted (): void {
    const { programme } = this;

    if (!programme) {
      return;
    }

    if (programme.hasGlobal) {
      this.classes.online[0].students = this.classes.online[0].students.concat(this.classes.unsorted);
    }
    if (programme.hasLocal && this.classes.onsite.length > 0) {
      // To sort students into the onsite class with students from the same clc,
      // I first have to find the related clc to the given student id and then iterate through
      // all classrooms and through all students within that classroom to find a class with the same clc
      this.classes.unsorted.forEach((unsortedId: number) => {
        let relatedClc: any|null = null;
        programme.learningGroups.forEach((clc) => {
          if (clc.applicants) {
            const found = clc.applicants.find((applicant) => applicant.id === unsortedId);
            if (found) {
              relatedClc = clc;
            }
          }
        });
        if (relatedClc) {
          this.classes.onsite.forEach((onsiteClc: any, clcIndex: number) => {
            if (relatedClc) {
              const matchedClc = onsiteClc.students.find((onsiteStudent: any) => {
                const onsiteStudentClc = programme.learningGroups.find((clc) => clc.applicants && clc.applicants.find((applicant) => applicant.id === onsiteStudent));
                return !!onsiteStudentClc && onsiteStudentClc.id === relatedClc.id;
              });

              if (matchedClc) {
                this.classes.onsite[clcIndex].students.push(unsortedId);
                relatedClc = null;
              }
            }
          });
          if (relatedClc) {
            this.classes.onsite[0].students.push(unsortedId);
          }
        }
      });
    }
  }

  studentClass (studentId: number): Record<string, boolean> {
    return {
      'jwl-classroom-student--new': this.classes.unsorted && this.classes.unsorted.includes(studentId),
    };
  }

  addClass (): void {
    this.newClasses[this.state].push(this.classes[this.state].length);
    this.classes[this.state].push({
      id: null,
      index: this.classes[this.state].length,
      name: 'new',
      students: [],
      courses: this.classes[this.state][0].courses,
    });
  }

  removeClass (classIndex: number): void {
    if (this.removeAllowed(classIndex)) {
      this.classes[this.state].splice(classIndex, 1);
      this.newClasses[this.state].pop();
    }
  }

  removeAllowed (classIndex: number): boolean {
    return this.classes[this.state][classIndex].students.length === 0;
  }

  mounted (): void {
    this.fetchData();
  }
}
