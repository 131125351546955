









import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Programme, ProgrammeApplicant, ProgrammeLearningGroup } from '@/store/interfaces/Programme';

interface FindingData {
  clc: ProgrammeLearningGroup;
  student: ProgrammeApplicant;
}

@Component
export default class SisClassroomStudent extends Vue {
  @Prop(Object)
  sisProgramme!: Programme;

  @Prop(Number)
  applicantId!: number;

  get studentData (): FindingData|null {
    let finding: FindingData|null = null;

    this.sisProgramme.learningGroups.forEach((clc) => {
      if (clc.applicants) {
        const found = clc.applicants.find((applicant) => applicant.id === this.applicantId);
        if (found) {
          finding = {
            clc,
            student: found,
          };
        }
      }
    });

    return finding;
  }
}
