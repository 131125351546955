























import Vue from 'vue';
import Component from 'vue-class-component';
import CommonFormElement, { SelectOption } from 'common-modules/src/components/CommonFormElement.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    CommonFormElement,
  },
})
export default class SisClassroomFacilitators extends Vue {
  @Prop(Array)
  programmeCourses!: any[];

  @Prop(Array)
  programmeFacilitators!: any[];

  @Prop(Array)
  classCourses!: any[];

  getCourse (courseId: number): any|undefined {
    return this.programmeCourses.find((course) => course.id === courseId);
  }

  updateFacilitators (index: number, facilitators: SelectOption[]): void {
    const localCourses = this.classCourses;
    const localCourse = localCourses[index];
    const localFacilitators: string[] = [];
    facilitators.forEach((facilitator) => {
      if (facilitator && facilitator.id) {
        localFacilitators.push(facilitator.id);
      }
    });
    localCourse.facilitators = localFacilitators;
    this.$emit('update-facilitators', localCourses);
  }
}
